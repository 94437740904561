<template>
  <div class="content">
    <div class="page">
      <h2>视联网应用</h2>
      <div class="message">
        平台依托了视联动力“高清、实时，大规模、双向、结构性安全”传输视频的视联网技术优势，及时有效发现基层治理难题，打通行业管理部门横向壁垒，提高基层综合治理效率，让基层治理工作简约、高效。
      </div>
      <img src="../../../../assets/image/theBasicLevel/蒙版组 152.png" alt="" />
    </div>
  </div>
</template>
 
<script>
export default {
  components: {},
  name: "",
  data() {
    return {};
  },

  methods: {},
};
</script>
 
<style scoped lang="scss">
.content {
  padding: 0 16%;
  box-sizing: border-box;
  min-height: calc(100vh - 387px);
  width: 100%;
  margin-bottom: 50px;
  .page {
    margin-top: 50px;
    width: 100%;
    height: 720px;
    background-color: #fff;
    box-sizing: border-box;
    padding: 30px;
    // position: relative;
    text-align: center;
    > h2 {
      text-align-last: left;
      font-size: 28px;
      font-family: PingFang SC;
      font-weight: 600;
      color: #333333;
      margin: 10px 0 30px 0;
    }
    .message {
      text-align-last: left;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #6b6b6b;
      margin-bottom: 50px;
    }
  }
}
</style>